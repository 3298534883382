*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body{
  overflow-x: hidden;
}


#container-top{
  height: 100vh;
}

.container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 50px;
}


/* LOADER*/

#loader, #loader2{
  position: absolute;
  height: 100vh;
  width: 100vw;
}

#loader{
  background-color: #191919;
  z-index: 2000;
}

#loader2{
  background-color: whitesmoke;
  z-index: 999;
}

#loader p{
  position: relative;
  display: flex;
  flex-direction: column;
  top: 67%;
  left: 1100px;
  color: whitesmoke;
  font-size: 200px;
}

#loader #loading-bar{
  position: absolute;
  bottom: 0px;
  width: 1vw;
  height: 25px;
  background-color: whitesmoke;
}
