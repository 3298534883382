#projet-presentation .container{
    display: flex;
    height: 90vh;
   flex-direction: row;
   padding-top: 30px;
   gap: 25px;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 120px;
}

#top-left{
    display: flex;
    flex-direction: column;
}

.left div{
    display: flex;
    flex-direction: column;
}

#projet-presentation .left div:nth-child(1){
    gap: 52px;
}

#projet-presentation .left div:nth-child(2){
    gap: 22px;
}

.left h2{
   font-size: 40px;
   font-weight: 700;
   color: #000;
   text-align: right;
}

.left #text-explanatory{
    font-size: 16px;
    font-weight: 500;
    color: #000;
    text-align: left;
}


.left h4{
    font-size: 30px;
    font-weight: 500;
    color: #000;
    text-align: left;
}

.left #language{
    font-size: 16px;
    font-weight: 500;
    color: #000;
    text-align: right;
}
/* #bottom-left{
    
} */

#bottom-left img{
    width: 100px;
}

/* .right img{
    height: 670px;
    width: 550px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
} */

.background-presentation{
    height:100%;
    width: 550px;
    border-radius: 5px;
}