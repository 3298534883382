/*Home*/
.home{
    display: flex;
    flex-direction: column;
    height: 100% ;
}

.home .container{
    display: flex;
    justify-content: column;
    height: 90vh;
}

main{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    padding-top: 150px;
}

main #bottom_main{
    position: relative;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}

/*HOME2*/
/* content about*/

.home2 #content_about{
    display: flex;
    flex-direction: column;
    padding-top: 240px;
    padding-bottom: 120px;
}

.home2 .container h3{
    font-size: 30px;
    color: #000;
    font-weight: 500;
}

.home2 .container .paragraph{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 65px;
    width: 520px;
}

.home2 .container div p{
    color: grey;
    font-size: 16px;
}

/*Paralax background*/

#backgroundParalax{
    width: 100%;
}


/*content_about2*/


#content_about2{
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding-top: 100px;
    padding-bottom: 180px;
    width: 100%;
}

#content_about2 h5{
    color: #000;
    font-size: 30px;
    font-weight: 300;
}

#content_about2 > div {
    display: flex;
   text-align: right;
    justify-content: end;
    width: 100%;
}

/*Selected Work*/

#selectedWork{
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: 100%;
    margin-bottom: 250px;
}

#selectedWork .top-work{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}

#selectedWork .top-work a{
    color: #000;
    text-decoration: none;
}

#selectedWork #content-work{
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
}

#selectedWork #content-work .left{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 35px;
    /* background-color: aqua; */
}

#selectedWork #content-work .right{
    display: flex;
    flex-direction: column;
    width: 100%
}

.card{
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
}


.card .card-picture{
    height: 390px;
    width: 100%;
    border-radius: 5px;
}

.card .card-bottom{
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    gap: 6px;
}

.card .card-bottom .title-card{
    font-size: 24px;
    font-weight: 400;
    color: #000;
}

.card .card-bottom .subtitle{
    font-size: 12px;
    color: grey;
}


.right .card .card-picture{
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

