/*HEADER*/
header{
    padding-top: 30px;
}

header .container{
    display: flex;
    flex-direction: row;
}

header .container h1{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
}

header .container .link-title{
    cursor: pointer;
    text-decoration: none;
    color: #000;
}

header .container h1 span{
    font-size: 20px;
    font-weight: 400;
}

header .container button{
    border: none;
    background: none;
    font-size: 20px;
}

header img{
    height: 30px;
    margin-right: 10px;
}

nav{
    position: absolute;
    z-index: 10;
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #191919;
    top: 0;
    bottom: 0;
    padding-top: 120px;
    padding-left: 50px;
    padding-bottom: 30px;
    width: 0.5;
    transform: translateX(-100%);
    transition: .4s ease;
}

.active nav{
    width: 60vw;
    transform: translateX(0%);
}

nav a{
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    text-decoration: none;
}

nav a span{
    color: grey;
    margin-right: 20px;
}

nav p{
    cursor: pointer;
}

nav #top-nav{
    display: flex;
    flex-direction: column;
}

nav #top-nav{
    display: flex;
    flex-direction: column;
    gap: 100px;
}

nav #top-nav #navigation-page{
    display: flex;
    flex-direction: column;
}

nav #top-nav #navigation-projet{
    display: flex;
    flex-direction: column;
}

.link{
   text-transform: uppercase;
}

#bottom-nav{
    display: flex;
    flex-direction: row;
    color: #fff;
    font-size: 12px;

}


.active{
    /* background-color: black; */
}