#project-container{
    display: flex;
    flex-direction: row;
    height: 100vh;
    align-items: center;
    gap: 20px;
}

 .card-project{
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(-10%);
    height: 400px;
}



/* .card-project div img{
    height: 440px;
    width: 100%;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
} */

.card-project p{
    font-size: 22px;
}

.card-project .background-card{
    background-size: cover;
    background-repeat: no-repeat;
    height: 440px;
    width: 320px;
    border-radius: 5px;
    background-color: red;
}