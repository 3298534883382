/*FOOTER*/
footer{
    padding-bottom: 30px;
}

footer .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    background-color: #D8D7CD;
    color: white;
    padding: 120px 0;
    border-radius: 10px;
}

footer .container h2{
    font-size: 40px;
}

footer .container p{
    text-align: center;
    font-size: 14px;
}